import React from 'react';
import {browserHistory} from 'react-router';
import {Link} from 'react-router';

function BackButton(props) {
    if(props.link === undefined){
        return(
            <button onClick={ browserHistory.goBack } className="back">&nbsp;</button>
        );
    }
    else{
        return(
           <Link to={props.link} className="back">&nbsp;</Link>
        );
    }
}

export default BackButton;