import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from "./helpers";
import global from "./global";
import Loading from "./loading";
import Error from "./error";
import PageHeader from './pageHeader';
import Social from './socialMedia';
import Gallery from './detailGallery';
import FooterMoreInfo from './footerMoreInfo';
import ReactHtmlParser from "react-html-parser";
import {Link} from 'react-router';

class BuyTicketsDetail extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isToLoad: true,
            error: null,
            data: [],
            nextItemId:null,
            previewsItemId:null,
            language: helpers.GetLanguageFromStorage()
        };
    }

    /* Set the state for next and prev items*/
    getPreviewsAndNextItems(){
        const list = helpers.getCalendarList();
        if(list === null) return;

        const posts = list["Posts"];
        let isThis = false;
        let hasNext = false;

        for(var item=0; item < posts.length; item++){
            if(isThis){
                this.setState({nextItemId:posts[item].Id});
                hasNext = true;
                break;
            }

            if(posts[item].Id.toString() === this.props.params.id.toString()){
                isThis = true;
                if(item !== 0){
                    this.setState({previewsItemId:posts[item-1].Id});
                }
                else{
                    this.setState({previewsItemId:null});
                }
            }

            if(!hasNext){
                this.setState({nextItemId:null});
            }

        }

    }

    componentWillReceiveProps() {
        this.setState({
            isToLoad: true
        });
        setTimeout(function () {
            this.loadDetail();
        }.bind(this), 1);
    }
    
    loadDetail(){
        this.getPreviewsAndNextItems();
        // Get list from API
        const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
        const API = global.websiteUrl + language + global.calendarDetail + this.props.params.id;

        fetch(API,
            {
                method: "POST",
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false,
                        data: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isToLoad: false,
                        language:helpers.GetLanguageFromStorage(),
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.loadDetail();
    }
    
    render() {
        const {error, isToLoad, data, nextItemId, previewsItemId, language} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        }
        else if (isToLoad) {
            return <Loading/>;
        }
        else {
            return (
                <div className="buy-tickets-container to-fade-in">
                    <PageHeader link="/buy-tickets" title={data.Title} />
                    
                    <div className="gallery-container">
                        <Countdown days={data.Translates.Days} hours={data.Translates.Hours} date={data.EndDate} />
                        <Gallery title={data.Title} images={data.Images} />
                    </div>
                    <Header previewsItemId={previewsItemId} nextItemId={nextItemId} data={data} />
                    <div className="container-desc">
                        <Description description={data.Description} />
                        <RenderOfficialWebsite data={data} />
                        <Social data={data} />
                        <a className="large-grey-button" href={data.BuyTicketLink} target="_blank" rel="noopener noreferrer">{data.Translates.BuyTicket}</a>
                    </div>
                    <img className="firstFoward" src="/content/images/graphics/firstFoward.svg" alt="First. Foward."/>
                    <Tickets data={data} />
                    <FooterMoreInfo lang={language} translates={data.Translates}/>
                </div>
            );
        }
    }
}

function PreviewsItem(props) {
    if (props.previewsItemId !== null) {
        return (
            <Link className="prev" to={"/buy-tickets/"+props.previewsItemId}></Link>
        );
    }
    return null;
}

function NextItem(props) {
    if (props.nextItemId !== null) {
        return (
            <Link className="next" to={"/buy-tickets/"+props.nextItemId}></Link>
        )
    }
    return null;
}

class Tickets extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            table: [],
            hasTable: false
        };
    }

    componentDidMount() {
        this.doTableObject();
    }
    
    /* Construction of the main object with all the price properties */
    doTableObject(){
        if(this.props.data.VariantsAttr === undefined){
            return;
        }
        
        let tableObj = [];
        const allDays = this.props.data.VariantsAttr.pa_race_calendar_day[0];
        const variants = this.props.data.Variants;
        const type = this.props.data.VariantsAttr.pa_race_calendar_type[0];
        
        Object.keys(type).map(function(objectKey, index) {
            const value = type[objectKey];
            let newObj = {
                name: value,
                pricesPerDay: []
            };

            for(let variant=0; variant < variants.length; variant++){
                if(objectKey !== variants[variant].attribute_pa_race_calendar_type){
                    continue;
                }
                
                let newPrices = {
                    day: variants[variant].attribute_pa_race_calendar_day,
                    price: variants[variant].price
                };

                newObj.pricesPerDay.push(newPrices);

            }

            // If missing days - add the missing ones without price "--"
            if(newObj.pricesPerDay.length !== Object.keys(allDays).length) {

                Object.keys(allDays).map(function (objKey, index) {
                    let hasDay = false;

                    for(let p=0; p<newObj.pricesPerDay.length; p++){
                        if (Object.values(newObj.pricesPerDay[p]).indexOf(objKey) > -1) {
                            hasDay = true;
                        }
                    }

                    if(!hasDay){
                        let newPrices = {
                            day: objKey,
                            price: '--'
                        };

                        newObj.pricesPerDay.splice(index, 0, newPrices);
                    }
                    return true;
                });
            }

            tableObj.push(newObj);
            return true;
        });

        // Verify if the week is free and set the property
        for(let t=0; t<tableObj.length; t++){
            let oldValue = null;
            let isEqual = true;

            for(let p=0; p<tableObj[t].pricesPerDay.length; p++){
                if(tableObj[t].pricesPerDay[p].price !== "--" || (oldValue !== null && tableObj[t].pricesPerDay[p].price !== oldValue)){
                    isEqual = false;
                }
                oldValue = tableObj[t].pricesPerDay[p].price;
            }

            tableObj[t].free = isEqual;
        }

        this.setState({table:tableObj, hasTable:true});

        return true;
    }

    render(){
        const {table, hasTable} = this.state;
        
        if(hasTable){
            return(
                <div className="container-desc">
                    <p className="tickets-desc">{this.props.data.Translates.TicketsAreAvailable}</p>

                    <div className="tickets-container">
                        <h3>{this.props.data.Translates.TicketsPrice}</h3>

                        <div className="table">
                            <header>
                                {Object.keys(this.props.data.VariantsAttr.pa_race_calendar_day[0]).map((keyName) => (
                                    <div key={keyName.toString()}><span>{this.props.data.VariantsAttr.pa_race_calendar_day[0][keyName].toString()}</span></div>
                                ))}
                            </header>
                            {table.map(({name, free, pricesPerDay}) =>
                                <TableRow key={name} free={free} name={name} pricesPerDay={pricesPerDay} freeTranslate={this.props.data.Translates.FreeEntrance} />
                            )}
                        </div>

                        <small>{this.props.data.Translates.ChildrenUnder}</small>
                    </div>
                </div>
            );
        }
        else {
            return(null);
        }
        
        
    }
}

function TableRow(props) {
    if(props.free){
        return <RederTypeWithFreePrice name={props.name} translate={props.freeTranslate} classCount={"count_"+props.pricesPerDay.length} />;
    }
    else{
        return <RederTypeWithPrices name={props.name} pricesPerDay={props.pricesPerDay} />;
    }
}

const RederTypeWithPrices = (props) => (
    <div className="prices-container">
        <h2>{props.name}</h2>
        <div>
            {props.pricesPerDay.map(({day, price}) =>
                <div key={day}>{ReactHtmlParser(price.replace(".00", ""))}</div>
            )}
        </div>
    </div>
);

const RederTypeWithFreePrice = (props) => (
    <div className="prices-container">
        <h2>{props.name}</h2>
        <div className={"free " + props.classCount}>{props.translate}</div>
    </div>
);

const Header = (props) => (
    <div className="header">
        <div>
            <h2>
                <span>{props.data.Translates.RaceCalendar}</span>
                <hr className='points-separator'/>
            </h2>
            <h3>{props.data.Title}</h3>
            <div className="date">{ReactHtmlParser(props.data.DateFormatted)}</div>
        </div>

        <div className="control-items">
            <PreviewsItem previewsItemId={props.previewsItemId} />
            <NextItem nextItemId={props.nextItemId} />
        </div>
    </div>
);

const Description = (props) => (
    <div className="description-container">{ReactHtmlParser(props.description.replace(">\r\n", ">\r").replace(">\r\n", ">\r").replace("&nbsp;", "<br /><br />"))}</div>
);

function RenderOfficialWebsite(props) {
    const toRender = props.data.WebsiteUrl !== "" && props.data.WebsiteImage !== "";
    if (toRender) {
        return <OfficialWebsite data={props.data}  />;
    }
    return null;
}

const OfficialWebsite = (props) => (
    <div className="website-container">
        <span>{props.data.Translates.OficialWebsite}</span>
        <a href={props.data.WebsiteUrl} target="_blank" rel="noopener noreferrer">
            <img src={props.data.WebsiteImage} alt={props.data.Title}/>
        </a>
        <hr className='points-separator'/>
    </div>
);

/* Count down */

class Countdown extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(new Date(parseInt(this.props.date)*1000));
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate) {
        let diff = (Date.parse(endDate) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    render() {
        const countDown = this.state;

        return (
            <div className="countdown">
              <div className="countdown-col-element">
                  <span>{this.addLeadingZeros(countDown.days)}</span>
                  <span>{this.props.days}</span>
              </div>
    
              <div className="countdown-col-element">
                <span>{this.addLeadingZeros(countDown.hours)}</span>
                <span>{this.props.hours}</span>
              </div>
            </div>
        );
    }
}

Countdown.propTypes = {
    date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
    date: new Date()
};

export default BuyTicketsDetail;