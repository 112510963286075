import React  from 'react';
import {Link} from "react-router";

const FooterMenu = () => (
    <nav className="footer-menu">
        <Link activeClassName="active" className="buy-tickets_btn" to="/buy-tickets">&nbsp;</Link>
        <Link activeClassName="active" className="drive-your-dream-car_btn" to="/drive-your-dream-car">&nbsp;</Link>
        <Link activeClassName="active" className="map_btn" to="/map">&nbsp;</Link>
        {/*<Link className="social" to="/map">&nbsp;</Link>*/}
    </nav>
);

export default FooterMenu;