import React from 'react';
import BackButton from "./backButton";

const PageHeader = (props) => (
    <div className="page-title-container">
        <BackButton link={props.link}/>
        <h2>{props.title}</h2>
        <ListButton buttonClass={props.buttonClass} changeListViewHandler={props.changeListViewHandler} />
    </div>
);

class ListButton extends React.Component {
    render() {
        if(this.props.buttonClass !== undefined){
            return (
                <button onClick={this.props.changeListViewHandler} className={this.props.buttonClass}>&nbsp;</button>
            );
        }
        else{
            return (null);
        }
        
    }
}

export default PageHeader;