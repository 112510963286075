import React, { PureComponent  } from 'react';
import helpers from "./helpers";
import global from "./global";
import Loading from "./loading";
import Error from "./error";
import PageHeader from './pageHeader';
import ReactHtmlParser from "react-html-parser";
import Social from './socialMedia';

class MapDetail extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isToLoad: true,
            error: null,
            data: [],
            bgColor: "#ffffff"
        };

    }
    
    goTop(){
        window.scroll(0, 0);
    }

    componentDidMount() {
        // Get list from API
        const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
        const API = global.websiteUrl + language + global.mapList + this.props.params.id;

        fetch(API,
            {
                method: "POST",
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin",

            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false,
                        data: result,
                        bgColor: result["Color"]
                    });

                },
                (error) => {
                    this.setState({
                        isToLoad: false,
                        error
                    });
                }
            )

    }
    
    render() {
        const {error, isToLoad, data, bgColor} = this.state;
        
        if (error) {
            return <Error message={error.message}/>;
        }
        else if (isToLoad) {
            return <Loading/>;
        }
        else {

            return (
                <div className={"marker-container to-fade-in " + bgColor}>
                    <PageHeader  link="/map" title={data.Title} />
                    <HeaderImage image={data.Image} title={data.Title} icon={data.Icon} />
                    <div className="map-container">
                        <div className="map-content">

                            <Logo title={data.Title} src={data.Logo}/>
                            
                            <h2 className="page-title">{data.Title}</h2>
                            <div className="content">
                                {ReactHtmlParser(data.Description)}
                            </div>
                            <hr className='points-separator'/>
                            <Social data={data} />

                            <div className="go-top-container">
                                <img src={data.graphic} alt={data.Title} />
                                <span onClick={this.goTop.bind(this)} className="go-top"> </span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            );
        }
    }
}

class Logo extends PureComponent {
    render(){
        if(this.props.src !== ""){
            return(
                <img className="logo" src={this.props.src} alt={this.props.title} />
            );
        }
        else{
            return(null);
        }
    }
}

const HeaderImage = (props) => (
    <div className="header-image-container">
        <div className="marker"><img src={props.icon} alt={props.title} /></div>
        <Image image={props.image} title={props.title} />
    </div>
);

const Image = (props) => (
    <figure>
        <img src={props.image} alt={props.title} />
    </figure>
);



export default MapDetail;