import React from 'react';

function Logo() {
    return(
        <figure>
            <img src="content/images/logo_dashboard.svg" alt="Autódromo Internacional do Algarve"/>
        </figure>
    );
}

export default Logo;