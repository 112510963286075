import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import Dashboard from './dashboard';
import Loading from './loading';
import NotFound from './notFound';
import renderHTML from "./renderHtml";
import global from './global';
import helpers from './helpers';

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            redirect: false,
            language: helpers.GetLanguageFromStorage() // Default EN
        };
    }

    componentDidMount() {
        if (helpers.getTranslates() == null) {
            const API = global.websiteUrl + helpers.GetLanguageFromStorage() + global.dashboardTranslates;

            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            items: result
                        });

                        localStorage.setItem(global.sessionStorage.translates, JSON.stringify(result));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        } else {
            this.setState({
                isLoaded: true,
                items: helpers.getTranslates()
            });
        }

    }

    setRedirect = () => {
        localStorage.setItem(global.sessionStorage.intro, "redirect");
        this.setState({
            redirect: true
        })
    };

    _onChange(index) {

        if (index === 3) {
            this.setRedirect();
        }

    }

    render() {
        const {redirect, language, items, error, isLoaded} = this.state;
        
        if (error) {
            return <NotFound/>;
        } else if (!isLoaded) {
            return <Loading/>;
        } else if (redirect) {
            return (
                <Dashboard language={language}/>
            );
        } else {
            return (
                <div className="intro">
                    <Carousel onChange={this._onChange.bind(this)} className="presentation-mode" emulateTouch={true}
                              showArrows={false} showThumbs={false} showStatus={false}>
                        <div className="slide_1">
                            <div>
                                <img src="/content/images/intro/calendar.svg" alt="slide 1" />
                                {renderHTML(items.Intro.Intro1)}
                            </div>
                        </div>
                        <div className="slide_2">
                            <div>
                                <img src="/content/images/intro/steeringWheel.svg" alt="slide 2" />
                                {renderHTML(items.Intro.Intro2)}
                            </div>
                        </div>
                        <div className="slide_3">
                            <div>
                                <img src="/content/images/intro/stayTouch.svg" alt="slide 3" />
                                {renderHTML(items.Intro.Intro3)}
                            </div>
                        </div>
                        <div className="slide_4"></div>
                    </Carousel>
                    <span className="skip" onClick={this.setRedirect.bind(this)}>{items.Intro.Skip}</span>
                </div>
            );
        }

    }
}

export default Slider;