import React from 'react';
import {Link} from 'react-router';
import Navigation from "./menu";

class Subpages extends React.Component {

    render() {
        return (
            <div className="page">
                <header>
                    <figure>
                        <Link to="/">
                            <img src="/content/images/page_logo.svg" alt="Autódromo Internacional do Algarve" />
                        </Link>
                    </figure>
                    <Navigation/>
                </header>
                {this.props.children}
            </div>
        );
    }
}

export default Subpages;