import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import global from "./global";
import helpers from "./helpers";
import NotFound from './notFound';
import Logo from './logo';
import Error from './error';
import ReactHtmlParser from "react-html-parser";
import renderHTML from "./renderHtml";
import NumberFormat from 'react-number-format';

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToLoad: false,
            error: null,
            isLogged: false,
            userData: [],
            translatesData: [],
            responseMessage: null,
            errorEditingAccount: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /* Form Submit */
    handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        this.setState({
            isToLoad: true,
            responseMessage: null
        });

        fetch(global.websiteUrl + global.editProfile,
            {
                method: "POST",
                body: data,
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false
                    });
                    if (result != null && result.status === true) {
                        // Set success state
                        this.setState({errorEditingAccount: false});
                        // Save user with new data to cache
                        helpers.setUserProfile(JSON.stringify(result.user));
                    } else {
                        // Set error state
                        this.setState({errorEditingAccount: true});
                    }

                    this.setState({responseMessage: result.msg});
                },
                (error) => {
                    this.setState({
                        isToLoad: false,
                        error
                    });

                    this.setState({isToLoad: false});
                }
            )
    }

    componentDidMount() {
        // Get User information from cache
        const profileFromCache = helpers.getUserProfile(); 
        if (profileFromCache != null) {
            
            this.setState({
                isLogged:true,
                userData: profileFromCache
            });
            
            // Get Translates from API
            if (helpers.getCreateAccountTranslates() == null) {
                const API = global.websiteUrl + helpers.GetLanguageFromStorage() + global.createAccountTranslates;

                fetch(API,
                    {
                        method: "POST",
                        mode: "cors",
                        cache: "no-store",
                        credentials: "same-origin",

                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                isToLoad: false,
                                translatesData: result
                            });

                            sessionStorage.setItem(global.sessionStorage.createAccountTranslates, JSON.stringify(result));
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isToLoad: false,
                                error
                            });
                        }
                    )
            } else {
                this.setState({
                    isToLoad: false,
                    translatesData: helpers.getCreateAccountTranslates()
                });
            }
        }

    }

    render() {
        const {error, isToLoad, userData, translatesData, isLogged, errorEditingAccount, responseMessage} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        }
        else if(isLogged) {
            return (
                <div className="create-account-container edit">
                    <div>
                        <EditProfileForm
                            errorEditingAccount={errorEditingAccount}
                            responseMessage={responseMessage}
                            handleSubmit={this.handleSubmit}
                            userData={userData}
                            translatesData={translatesData}
                            isToLoad={isToLoad}/>
                    </div>
                </div>
            );
        }
        else{
            return <NotFound />;
        }
    }

}

class EditProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: this.props.userData.country
        };
    }

    bindCountry(event){
        this.setState({selectedCountry:event.target.value.toLowerCase()});
    }
    
    render() {
        const {selectedCountry} = this.state;
        const countries = this.props.translatesData.Countries;
        const containerClasses = this.props.isToLoad ? "form-container load" : "form-container";

        return (
            <div>
                <button onClick={browserHistory.goBack} className="colapse">&nbsp;</button>
                <div className="title">{this.props.userData.translates.editProfile}</div>
                <div className={containerClasses}>
                    <LoadingSpot isToLoad={this.props.isToLoad} />
                    <form onSubmit={this.props.handleSubmit}>
                        {/*<input defaultValue={this.props.userData.phone} type="hidden" name="billing_phone" value={this.props.translatesData.phone}/>*/}

                        <fieldset>
                            <input defaultValue={helpers.getUsername()} type="hidden" name="username"/>
                            <input defaultValue={helpers.getPassword()} type="hidden" name="password"/>
                            <input defaultValue={this.props.userData.firstName} required type="text" name="billing_first_name"
                                   placeholder={this.props.translatesData.Translates.FirstName}/>
                            <input defaultValue={this.props.userData.lastName} required type="text" name="billing_last_name"
                                   placeholder={this.props.translatesData.Translates.LastName}/>
                            <input defaultValue={this.props.userData.email} required type="email" name="billing_email"
                                   placeholder={this.props.translatesData.Translates.Email}/>
                            <input defaultValue={this.props.userData.address} required type="text" name="billing_address_1"
                                   placeholder={this.props.translatesData.Translates.Address}/>
                                   
                            <ZipCode zipcode={this.props.userData.zipcode} country={selectedCountry} translate={this.props.translatesData.Translates.Postal} />
                            
                            <input defaultValue={this.props.userData.city} required type="text" name="billing_city" placeholder={this.props.translatesData.Translates.City}/>
                            <select onChange={this.bindCountry.bind(this)} defaultValue={this.props.userData.country} required name="billing_country">
                                <option disabled>{this.props.translatesData.Translates.Country}</option>
                                {Object.keys(countries).map((keyName) => (
                                    <option value={keyName}
                                            key={keyName.toString()}>
                                        {ReactHtmlParser(countries[keyName].toString())}
                                    </option>
                                ))}
                            </select>
                            <input defaultValue={this.props.userData.company} type="text" name="billing_company" placeholder={this.props.translatesData.Translates.Company}/>
                        </fieldset>
                        <button type="submit">{this.props.userData.translates.editProfile}</button>
                    </form>

                    <ErrorMessage hasError={this.props.errorEditingAccount}
                                  errorMessage={this.props.responseMessage}/>

                    <hr className='points-separator'/>
                    <Logo/>
                </div>
            </div>
        );
    }
}

function ZipCode(props) {
    switch(props.country.toLowerCase()) {
        case "pt":
        case "br":
            return (
                <NumberFormat defaultValue={props.zipcode} format="####-###" mask="_" required name="billing_postcode" placeholder={props.translate}/>
            );
        case "at":
        case "ch":
            return (
                <NumberFormat defaultValue={props.zipcode} format="####" mask="_" required name="billing_postcode" placeholder={props.translate}/>
            );
        case "de":
        case "es":
        case "fr":
            return (
                <NumberFormat defaultValue={props.zipcode} format="#####" mask="_" required name="billing_postcode" placeholder={props.translate}/>
            );
        case "jp":
            return (
                <NumberFormat defaultValue={props.zipcode} format="####-####" mask="_" required name="billing_postcode" placeholder={props.translate}/>
            );
        case "us":
            return (
                <NumberFormat defaultValue={props.zipcode} format="#####-####" mask="_" required name="billing_postcode" placeholder={props.translate}/>
            );
        case "pl":
            return (
                <NumberFormat defaultValue={props.zipcode} format="##-###" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        default:
            return(
                <input defaultValue={props.zipcode} required pattern="[0-9]+([-\,][0-9]+)?" type="text" name="zipCode" placeholder={props.translate}/>
            );
    }
}

function ErrorMessage(props) {
    if(props.responseMessage !== null){
        if(props.hasError){
            return (
                <div className="errorMessage">{renderHTML(props.errorMessage)}</div>
            );
        }
        else{
            return (
                <div className="successMessage">{renderHTML(props.errorMessage)}</div>
            );
        }
    }
    else {
        return(null);
    }
}

class LoadingSpot extends Component {
    render() {
        if(this.props.isToLoad){
            return (
                <div className="loading">
                    <img src="content/images/loading.gif" alt="loading"/>
                </div>
            );
        }
        else{
            return(null);
        }
    }
}

export default EditProfile;