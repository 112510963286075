import global from "./global";

let helpers = {
    getUserAndPassDecoded: function () {
        const credentials = localStorage.getItem(global.sessionStorage.loginCredentials);
        
        if(credentials === null){
            return null;
        }
        return window.atob(credentials);
    },
    setUserAndPassEncoded: function (email, pw) {
        localStorage.setItem(global.sessionStorage.loginCredentials, window.btoa(email + ':' + pw));
    },
    setUserProfile: function (result) {
        localStorage.setItem(global.sessionStorage.profile, result);
    },
    getUsername: function () {
        const userAndPass = this.getUserAndPassDecoded();
        
        if(userAndPass === null){
            return null;
        }
        
        return userAndPass.split(':')[0];
    },
    getPassword: function () {
        const userAndPass = this.getUserAndPassDecoded();

        if(userAndPass === null){
            return null;
        }

        return userAndPass.split(':')[1];
    },
    getUserProfile: function () {
        var profile = localStorage.getItem(global.sessionStorage.profile);
        
        if(profile == null){
            return null;
        }
        return JSON.parse(profile);
    },
    getTranslates: function() {
        var translates = localStorage.getItem(global.sessionStorage.translates);

        if(translates == null){
            return null;
        }
        return JSON.parse(translates);
    },
    getCreateAccountTranslates: function() {
        var translates = sessionStorage.getItem(global.sessionStorage.createAccountTranslates);

        if(translates == null){
            return null;
        }
        return JSON.parse(translates);
    },
    setMapFilters: function(filters) {
        sessionStorage.setItem(global.sessionStorage.mapFilters, filters);
    },
    getMapFilters: function() {
        var filters = sessionStorage.getItem(global.sessionStorage.mapFilters);

        if(filters == null){
            return [];
        }
        return filters.split(',').map(Number);
        
    },
    setMapCat: function(areAllCat) {
        sessionStorage.setItem(global.sessionStorage.mapAllCategoriesChecked, areAllCat);
    },
    getMapCat: function() {
        var areAllCat = sessionStorage.getItem(global.sessionStorage.mapAllCategoriesChecked);

        if(areAllCat == null){
            return true;
        }
        return areAllCat === "true";
    },
    GetLanguageFromStorage: function() {
        // EN per default
        return localStorage.getItem(global.sessionStorage.language) == null ? '' : localStorage.getItem(global.sessionStorage.language);
    },
    getCalendarList: function() {
        var calendarList = sessionStorage.getItem(global.sessionStorage.calendarList);

        if(calendarList == null){
            return null;
        }
        return JSON.parse(calendarList);
    },
    getExperiencesList: function() {
        var experiencesList = sessionStorage.getItem(global.sessionStorage.experiencesList);

        if(experiencesList == null){
            return null;
        }
        return JSON.parse(experiencesList);
    },
    getMapList: function() {
        var mapList = sessionStorage.getItem(global.sessionStorage.mapList);

        if(mapList == null){
            return null;
        }
        return JSON.parse(mapList);
    },
    setLanguage: function(lang){
        sessionStorage.clear();
        sessionStorage.setItem(global.sessionStorage.openAppSessionFirstTime, "false");
        localStorage.removeItem(global.sessionStorage.profile);
        localStorage.removeItem(global.sessionStorage.translates);
        
        if(lang === ""){ // If default doesn't need the value storage
            localStorage.removeItem(global.sessionStorage.language);
        }
        else{
            localStorage.setItem(global.sessionStorage.language, lang);
        }
        
        return true;
    },
    logout: function () {
        localStorage.removeItem(global.sessionStorage.loginCredentials);
        localStorage.removeItem(global.sessionStorage.profile);
    },
    isLogged: function () {
        return (localStorage.getItem(global.sessionStorage.loginCredentials) !== null) && (localStorage.getItem(global.sessionStorage.profile) !== null);
    },
    isTypeList: function() {
        let isList = true;
        const listTypeFromCache = localStorage.getItem(global.sessionStorage.buyTicketsListType);
        
        if(listTypeFromCache !== null && listTypeFromCache === "tile") {
            isList = false;
        }
        
        return isList;
    }
};

export default helpers;