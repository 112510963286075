let global = {
    websiteUrl: 'https://autodromodoalgarve.com/',
    dashboardTranslates: 'wp-json/app/translates',
    createAccountTranslates: 'wp-json/app/users/register/get',
    login: 'wp-json/app/users/login',
    forgotPw: 'wp-json/app/users/forgotpw',
    createAccount: 'wp-json/app/users/register',
    editProfile: 'wp-json/app/users/edit',
    calendar: 'wp-json/app/calendar',
    calendarDetail: 'wp-json/app/race/',
    driveYourDreamCar: 'wp-json/app/experiences',
    driveYourDreamCarDetail: 'wp-json/app/experience/',
    mapList: 'wp-json/app/map/',
    // Session Storage Keys
    sessionStorage: {
        intro:'aia-intro',
        loginCredentials:'aia-cred',
        language:'aia-language',
        profile:'aia-profile',
        translates:'aia-dashboard_translates',
        createAccountTranslates:'aia-create-account-translates',
        buyTicketsListType:'aia-list-type',
        calendarList:'aia-calendar-list',
        experiencesList:'aia-experiences-list',
        mapList:'aia-map-list',
        openAppSessionFirstTime:'aia-session-first-time',
        mapFilters:'aia-map-filters',
        mapAllCategoriesChecked:'aia-map-all-cat'
    }
};

export default global;