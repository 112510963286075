import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {browserHistory, Link} from 'react-router';
import Loading from './loading';
import Logo from './logo';
import renderHTML from './renderHtml';
import Error from './error';
import global from './global';
import helpers from './helpers';
import NumberFormat from 'react-number-format';

/* 
** MAIN COMPONENT
*/

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    static getTranslatesFromCache() {
        if (helpers.getTranslates() == null) {
            return null;
        } else {
            return JSON.parse(helpers.getTranslates());
        }
    }

    componentDidMount() {
        
        /* If user is logged in 
         * and
         * open the app session at the first time 
         * -> Redirect to the map area*/
        if(sessionStorage.getItem(global.sessionStorage.openAppSessionFirstTime) == null && helpers.getUserAndPassDecoded() !== null ) {
            sessionStorage.setItem(global.sessionStorage.openAppSessionFirstTime, "false");
            browserHistory.push('/map');
        }
        
        // Get Translates from API
        if (helpers.getTranslates() == null) {
            const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
            const API = global.websiteUrl + language  + global.dashboardTranslates;
            
            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            items: result
                        });

                        localStorage.setItem(global.sessionStorage.translates, JSON.stringify(result));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        } else {
            this.setState({
                isLoaded: true,
                items: helpers.getTranslates()
            });
        }
    }

    render() {
        const {error, isLoaded, items} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        } else if (!isLoaded) {
            return <Loading/>;
        } else {
            return(
                <DashboardContainer items={items}/>
            );
        }
    }
}

/* 
** SUB COMPONENTS
*/

/* All the dashboad container*/
class DashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateAccount: false,
            reRenderLogin: false
        };

        this.handleSetCreateAccount = this.handleSetCreateAccount.bind(this);
        this.handleRemoveCreateAccount = this.handleRemoveCreateAccount.bind(this);
        this.handleReRenderLogin = this.handleReRenderLogin.bind(this);
    }

    handleReRenderLogin(){
        this.setState({reRenderLogin:true});
    }
    
    handleSetCreateAccount(){
        this.setState({showCreateAccount:true});
    }

    handleRemoveCreateAccount(){
        this.setState({showCreateAccount:false});
        
    }
    
    render(){
        return(
            <div className="dashboard container">
                <Header reRenderLogin={this.handleReRenderLogin} handleRemoveCreateAccount={this.handleRemoveCreateAccount} showCreateAccount={this.state.showCreateAccount} name={this.props.items.Dashboard.FPTitle}/>
                <div className="main-content row">
                    <div className="menu-container col-12-p20">
                        <div>
                            <Logo/>
                            <div className='col-12'>
                                <hr className='points-separator'/>
                            </div>
                            <Menu items={this.props.items.DashboarMenu}/>
                        </div>
                    </div>
        
                    <Login reRenderLogin={this.state.reRenderLogin} handleSetCreateAccount={this.handleSetCreateAccount} handleRemoveCreateAccount={this.handleRemoveCreateAccount} items={this.props.items.Dashboard}/>

                    <Supported items={this.props.items.Dashboard}/>
                </div>
            </div>
        );
    };
}

/* Sub-sub-components from dashboard */

class Header extends Component {
    render() {
        let classes = this.props.showCreateAccount ? "header row with-create-account" : "header row";
        
        return (
            <div className={classes}>
                <div className="col-12-p20">
                    <CreateAccount reRenderLogin={this.props.reRenderLogin} handleRemoveCreateAccount={this.props.handleRemoveCreateAccount} showCreateAccount={this.props.showCreateAccount} />
                    <div className="header-title">
                        <h1>{this.props.name}</h1>    
                    </div>
                </div>
            </div>
        );
    }
}

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToLoad: true,
            error: null,
            responseMessage: null,
            errorCreatingAccount: null,
            success: false,
            data: [],
            zipCode: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /* Form Submit */
    handleSubmit(event){
        event.preventDefault();

        const data = new FormData(event.target);

        this.setState({isToLoad: true});

        fetch(global.websiteUrl + global.createAccount,
            {
                method: "POST",
                body: data,
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false
                    });

                    if(result != null && result.hasOwnProperty('code') && result.code === 200){
                        // Set success state
                       this.setState({errorCreatingAccount: false});
                       this.setState({success: true});

                       // Auto login after create account
                       helpers.setUserAndPassEncoded(data.get("username"), data.get("password"));
                       
                       this.props.reRenderLogin();
                    }
                    else {
                        // Set error state
                        this.setState({errorCreatingAccount: true});
                    }

                    this.setState({responseMessage: result.message});
                },
                (error) => {
                    this.setState({
                        isToLoad: false,
                        error
                    });

                    this.setState({isToLoad: false});
                }
            )
    }
    
    componentDidMount() {
        // Get Translates from API
        if (helpers.getCreateAccountTranslates() === null) {
            const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
            const API = global.websiteUrl + language + global.createAccountTranslates;

            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isToLoad: false,
                            data: result
                        });

                        sessionStorage.setItem(global.sessionStorage.createAccountTranslates, JSON.stringify(result));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isToLoad: false,
                            error
                        });
                    }
                )
        } else {
            this.setState({
                isToLoad: false,
                data: helpers.getCreateAccountTranslates()
            });
        }

    }
            
    render() {
        const {error, isToLoad, data, success, responseMessage, errorCreatingAccount, zipCode} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        } else {
            if(this.props.showCreateAccount){
                if(success){
                    return(null);
                }
                else{
                    window.scroll(0, 0);
                    return (
                        <div className="create-account-container">
                            <div>
                                <CreateAccountForm
                                    handleRemoveCreateAccount={this.props.handleRemoveCreateAccount}
                                    responseMessage={responseMessage} 
                                    errorCreatingAccount={errorCreatingAccount} 
                                    handleSubmit={this.handleSubmit}
                                    zipCode={zipCode}
                                    data={data}
                                    isToLoad={isToLoad} />
                            </div>
                        </div>
                    );
                }
            }
            else{
                return(null);
            }
        }
    }
    
}

class CreateAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: helpers.GetLanguageFromStorage()
        };
    }
    
    bindCountry(event){
        this.setState({selectedCountry:event.target.value.toLowerCase()});
    }

    render() {
        const {selectedCountry} = this.state;
        const countries = this.props.data.Countries;
        const className = this.props.isToLoad ? "form-container load" : "form-container";
        const lang = helpers.GetLanguageFromStorage() === "" ? "GB" : "PT"; // Pre-select the country
        
        return (
            <div>
                <button onClick={this.props.handleRemoveCreateAccount} className="colapse">&nbsp;</button>
                <div className="title">{this.props.data.Translates.CreateAccount}</div>
                <div className={className}>
                    <h2>{this.props.data.Translates.Intro}</h2>
    
                    <LoadingSpot isToLoad={this.props.isToLoad} />
                    
                    <form onSubmit={this.props.handleSubmit}>
                        <input type="hidden" name="zipCode" value={this.props.zipCode} />
    
                        <fieldset>
                            <input required autoComplete="off" type="text" name="username" placeholder={this.props.data.Translates.Username}/>
                            <input required autoComplete="off" type="password" minLength="4" name="password" placeholder={this.props.data.Translates.Password}/>
                            <input required type="text" name="firstName" placeholder={this.props.data.Translates.FirstName}/>
                            <input required type="text" name="lastName" placeholder={this.props.data.Translates.LastName}/>
                            <input required type="email" name="email" placeholder={this.props.data.Translates.Email}/>
                            <input required type="text" name="address" placeholder={this.props.data.Translates.Address}/>
                            <ZipCode country={selectedCountry} translate={this.props.data.Translates.Postal} />
                            <input required type="text" name="city" placeholder={this.props.data.Translates.City}/>
                            <select onChange={this.bindCountry.bind(this)} defaultValue={lang} required name="country">
                                <option disabled>{this.props.data.Translates.Country}</option>
                                {Object.keys(countries).map((keyName) => (
                                    <option value={keyName} key={keyName.toString()}>{ReactHtmlParser(countries[keyName].toString())}</option>
                                ))}
                            </select>
                            <input type="text" name="company" placeholder={this.props.data.Translates.Company}/>
                        </fieldset>
                        <button type="submit">{this.props.data.Translates.CreateMyAccount}</button>
                    </form>
                    
                    <ErrorMessage hasError={this.props.errorCreatingAccount} errorMessage={this.props.responseMessage} />
                    
                    <hr className='points-separator'/>
                    <Logo/>
                </div>
            </div>
        ); 
        
    }
}

function ZipCode(props) {
    switch(props.country.toLowerCase()) {
        case "pt":
        case "br":
            return (
                <NumberFormat format="####-###" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        case "at":
        case "ch":
            return (
                <NumberFormat format="####" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        case "de":
        case "es":
        case "fr":
            return (
                <NumberFormat format="#####" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        case "jp":
            return (
                <NumberFormat format="####-####" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        case "us":
            return (
                <NumberFormat format="#####-####" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        case "pl":
            return (
                <NumberFormat format="##-###" mask="_" required name="zipCode" placeholder={props.translate}/>
            );
        default:
            return(
                <input required pattern="[0-9]+([-\,][0-9]+)?" type="text" name="zipCode" placeholder={props.translate}/>
            );
    }
}

class Menu extends Component {
    render() {
        return (
            <div className="menu">
                <ul>
                    <MenuItem name={this.props.items.BuyTickets} icon={'content/images/menu/calendar_home.svg'} href='/buy-tickets'/>
                    <MenuItem name={this.props.items.DriveYourDreamCar} icon={'content/images/menu/experiences_home.svg'} href='/drive-your-dream-car'/>
                    <MenuItem name={this.props.items.Map} icon={'content/images/menu/iconMap.svg'} href='/map'/>
                </ul>
            </div>
        );
    }
}

class MenuItem extends Component {
    render() {
        return (
            <li>
                <Link to={this.props.href}>
                    <img src={this.props.icon} alt={this.props.name}/>
                    <span>{this.props.name}</span>
                </Link>
            </li>
        );
    }
}

class LoadingSpot extends Component {
    render() {
        if(this.props.isToLoad){
            return (
                <div className="loading">
                    <img src="content/images/loading.gif" alt="loading"/>
                </div>
            );
        }
        else{
            return(null);
        }
    }
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error: null,
            data: [],
            emailType: 'password',
            email: '',
            emailToRecovery: '',
            password: '',
            loginSuccess: false,
            loginError: false,
            isForgotPasswordArea: false,
            forgotPasswordFetchMessage: null,
            forgotPasswordSuccess: false
        };

        this.handleEmailType = this.handleEmailType.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.handleIsForgotArea = this.handleIsForgotArea.bind(this);
        this.handleIsLoginArea = this.handleIsLoginArea.bind(this);
        this.handleRecoverSubmit = this.handleRecoverSubmit.bind(this);
    }
    
    componentDidMount() {
        this.setLoggedUserIfInCache();
    }
    
    componentWillReceiveProps() {
        this.setLoggedUserIfInCache();
    }

    /* Do Login Fetch */
    doLoginFetch(){
        var formData = new FormData();
        formData.append('username', this.state.email);
        formData.append('password', this.state.password);

        this.setState({isToLoad: true});

        fetch(global.websiteUrl + global.login,
            {
                method: "POST",
                body: formData,
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin",

            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false,
                        data: result
                    });

                    if(result != null && !result.hasOwnProperty('error') && !result.hasOwnProperty('code')){
                        helpers.setUserAndPassEncoded(this.state.email, this.state.password);
                        helpers.setUserProfile(JSON.stringify(result));

                        // Set success state
                        this.setState({loginSuccess: true});
                    }
                    else {
                        // Set error login state
                        this.setState({loginError: true});
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });

                    this.setState({isToLoad: false});
                }
            )
    }

    /* Get profile from cache */
    setLoggedUserIfInCache() {
        const userProfile = helpers.getUserProfile();
        
        if (userProfile != null) {
            this.setState({
                loginSuccess: true,
                data: userProfile
            });
        }
        else if(helpers.getUsername() !== null && helpers.getPassword() !== null) {
            this.setState({
                isLoaded: false,
                email: helpers.getUsername(),
                password: helpers.getPassword()
            });
            
            let _this = this;
            setTimeout(function () {
                _this.doLoginFetch();
            }, 100)
        }
    }

    /* update email state value */
    handleEmail(event){
        let value = "";
        
        if(event.target.value !== undefined) {
            value = event.target.value;
        }
        this.setState({email: value});
    }

    /* update password state value */
    handlePassword(event){
        let value = "";

        if(event.target.value !== undefined) {
            value = event.target.value;
        }
        this.setState({password: value});
    }
    
    /* change the email input type */
    handleEmailType(event) {
        if (this.state.emailType === 'password') {
            this.setState({emailType: 'text'});
        } else {
            this.setState({emailType: 'password'});
        }
    }

    /* Logout*/
    doLogout(){
        helpers.logout();
        
        this.setState({
            loginSuccess: false,
            loginError: false,
            data: []
        });
    }
    
    /* Change to forgot password area */
    handleIsForgotArea(event){
        event.preventDefault();
        
        this.setState({
            error: null,
            data: [],
            loginSuccess: false,
            loginError: false,
            isForgotPasswordArea: true
        });
    }

    /* Change to login area */
    handleIsLoginArea(event){
        event.preventDefault();

        this.setState({
            isForgotPasswordArea: false,
            forgotPasswordFetchMessage:null,
            forgotPasswordSuccess: false
        });
    }
    
    /* Login Form Submit */
    handleLoginSubmit(event){
        event.preventDefault();
        
        // Hide create account
        this.props.handleRemoveCreateAccount();
        this.doLoginFetch();
    }
    
    /* Recover Form Submit */
    handleRecoverSubmit(event){
        event.preventDefault();

        let formData = new FormData();
        formData.append('action', '_user_lostpassword');
        formData.append('app', 'true');
        formData.append('adress_type', 'billing');
        formData.append('user_login', this.state.email);

        this.setState({isToLoad: true});

        fetch(global.websiteUrl + global.forgotPw,
            {
                method: "POST",
                body: formData,
                mode: "cors"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false,
                        data: result
                    });

                    if(result != null ){
                        this.setState({forgotPasswordFetchMessage: result.msg});
                        this.setState({forgotPasswordSuccess: result.status});
                    }
                    
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });

                    this.setState({isToLoad: false});
                }
            )
    }
    
    render() {
        const {emailType, error, loginError, data, loginSuccess, isForgotPasswordArea, forgotPasswordFetchMessage, forgotPasswordSuccess} = this.state;

        if (error) {
            return <Error message={error.message} />;
        }
        /*
        * Forgot password
        * */
        else if(isForgotPasswordArea){
            
            return (
                <div className="login-container col-12-p20">
                    <div>
                        <LoadingSpot isToLoad={this.state.isToLoad} />

                        <h2>{this.props.items.ForgotPassword}</h2>
                        <hr className='points-separator'/>
                        
                        <form onSubmit={this.handleRecoverSubmit}>
                            <fieldset>
                                <input onChange={this.handleEmail} required type="email"
                                       placeholder={this.props.items.Email}/>
                            </fieldset>
                            <button type="submit">{this.props.items.Recover}</button>
                        </form>
                        
                        <ForgotPwMessage message={forgotPasswordFetchMessage} status={forgotPasswordSuccess} />

                        <div className="forgot-password">
                            <span onClick={this.handleIsLoginArea}>{this.props.items.Login}</span>
                        </div>
                    </div>
                </div>
            );
        }
        /*
        * Login
        * */
        else {
            
            return (
                <div className="login-container col-12-p20">
                    <div>
                        <LoadingSpot isToLoad={this.state.isToLoad} />
                        
                        <h2>{loginSuccess ? this.props.items.LoggedIn : this.props.items.Login}</h2>
                        <hr className='points-separator'/>
                        
                        <LoginForm
                            handleSetCreateAccount={this.props.handleSetCreateAccount} 
                            handleSubmit={this.handleLoginSubmit} 
                            handleChangeEmail={this.handleEmail} 
                            handleChangePassword={this.handlePassword} 
                            handleChangeEmailType={this.handleEmailType}
                            handleDoLogout={this.doLogout}
                            emailType={emailType}
                            loginSuccess={loginSuccess}
                            loginError={loginError}
                            errorMessage={this.props.items.LoginError}
                            logout={this.props.items.Logout}
                            viewProfile={this.props.items.ViewProfile}
                            data={data}
                            items={this.props.items} />
                        
                        <ForgotPassword
                            handleIsForgotArea={this.handleIsForgotArea}
                            text={this.props.items.ForgotPassword}
                            loginSuccess={loginSuccess} />
                    </div>
                    
                </div>
            );
        }
    }
}

function ErrorMessage(props) {
    if(props.hasError){
        return (
            <div className="errorMessage">{renderHTML(props.errorMessage)}</div>
        );
    }
    else {
        return(null);
    }
}

function ForgotPwMessage(props) {
    if(props.status){
        return (
            <div className="successMessage">{renderHTML(props.message)}</div>
        );
    }
    else {
        return (
            <div className="errorMessage">{renderHTML(props.message)}</div>
        );
    }
}

class LoginForm extends Component {
    render() {
        if(this.props.loginSuccess){ // If Logged in
            return (
                <div className="logged-in">
                    <figure>
                        <img src={this.props.data.picture} alt={this.props.data.firstName + " " + this.props.data.lastName} />
                    </figure>
                    <div>{this.props.data.firstName} {this.props.data.lastName}</div>
                    <Link
                          to={{
                              pathname: "/my-profile",
                              state: {
                                  transition: 'slideRight',
                              },
                          }}
                    >{this.props.viewProfile}</Link>
                    <button onClick={this.props.handleDoLogout}>{this.props.logout}</button>
                </div>
            );
        }
        else{ // If not logged in
            return (
                <div>
                    <div className="create-account">
                        <span>{this.props.items.SignInOr}</span>&nbsp; 
                        <span onClick={this.props.handleSetCreateAccount}>{this.props.items.CreateAccount}</span>
                    </div>
                    <form onSubmit={this.props.handleSubmit}>
                        <fieldset>
                            <input onChange={this.props.handleChangeEmail} required type="text"
                                   placeholder={this.props.items.EmailOrUsername}/>
                            <input onChange={this.props.handleChangePassword} required type={this.props.emailType}
                                   placeholder={this.props.items.Password}/>
                        </fieldset>
                        <fieldset>
                            <label className="checkbox" htmlFor="showPassword">
                                <input onChange={this.props.handleChangeEmailType} id="showPassword" type="checkbox"
                                       name="showPassword"/>
                                <span>{this.props.items.ShowPassword}</span>
                            </label>
                        </fieldset>
                        <button type="submit">{this.props.items.SignIn}</button>
                    </form>
                    <ErrorMessage hasError={this.props.loginError} errorMessage={this.props.errorMessage} />
                </div>
            );
        }
        
    }
}

class ForgotPassword extends Component {
    render() {
        if(this.props.loginSuccess){
            return(null);
        }
        else{
            return (
                <div className="forgot-password">
                    <a onClick={this.props.handleIsForgotArea} href="/forgot-password">{this.props.text}</a>
                </div>
            );
        }
    }
}

class Supported extends Component {
    render() {
        return (
            <div className="support-container">
                <h2>{this.props.items["SupportedBy"]}</h2>
                <div>
                    {this.props.items["SupportedByImages"].map((url) => (
                        <a href={this.props.items["SupportedByLink"]} target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid" src={url} key={url} alt="" />
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}

export default Dashboard;