import React from 'react';

function Social(props) {
    let urlToShare = props.data.BuyTicketLink;
    
    if(props.data.hasOwnProperty("ShareLink")){
        if(props.data.ShareLink == null){
            return(null);
        }
        else{
            urlToShare = props.data.ShareLink;
        }
    }
    
    return(
        <div>
            <div className="social">
                <span>{props.data.Translates.ShareThis}</span>
                <a href={"https://m.facebook.com/sharer/sharer.php?u=" + urlToShare} rel="noopener noreferrer">
                    <img src="/content/images/graphics/iconFb.svg" alt="Facebook" />
                </a>
                <a href={"https://mobile.twitter.com/share?url="+urlToShare+"&text=" + props.data.Title} rel="noopener noreferrer">
                    <img src="/content/images/graphics/iconTwitter.svg" alt="Twitter" />
                </a>
                <a href={"mailto:?subject="+props.data.Title+"&body=" + urlToShare} rel="noopener noreferrer">
                    <img src="/content/images/graphics/iconEmail.svg" alt="Email" />
                </a>

            </div>
            <hr className='points-separator'/>
        </div>
    );
}

export default Social;