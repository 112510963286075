import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Link} from 'react-router';
import helpers from "./helpers";
import global from "./global";
import Loading from "./loading";
import Error from "./error";
import PageHeader from './pageHeader';
import FooterMenu from './footerMenu';

class DriveYourDreamCar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isToLoad: true,
            error: null,
            data: [],
            filteredPosts:[],
            filterValue: null,
            isListRow: helpers.isTypeList()
        };
    }

    componentDidMount() {
        // Get from API
        if (helpers.getExperiencesList() == null) {
            // Get list from API
            const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
            const API = global.websiteUrl + language + global.driveYourDreamCar;

            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isToLoad: false,
                            data: result,
                            filteredPosts: result["Posts"]
                        });

                        sessionStorage.setItem(global.sessionStorage.experiencesList, JSON.stringify(result));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isToLoad: false,
                            error
                        });
                    }
                )
        }
        else{
            this.setState({
                isToLoad: false,
                data: helpers.getExperiencesList(),
                filteredPosts: helpers.getExperiencesList()["Posts"]
                
            });
        }

    }

    changeListView(){
        const listType = this.state.isListRow ? "tile" : "list";
        localStorage.setItem(global.sessionStorage.buyTicketsListType, listType);
        this.setState({isListRow: !this.state.isListRow});
    }

    filterPosts = (value) => {
        let filteredPosts = this.state.data["Posts"];
        if(value !== null && value !== ""){

            filteredPosts = filteredPosts.filter((post) => {
                //console.log(post.TermIds);
                return post.TermIds.includes(parseInt(value));
            });
        }
       
        this.setState({
            filteredPosts
        })
    };
    
    handleSelectFilter(event){
        this.setState({filterValue: event.target.value});
        
        this.filterPosts(event.target.value);
        
    }

    render() {
        const {error, isToLoad, data, filteredPosts, isListRow} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        }
        else if (isToLoad) {
            return <Loading/>;
        }
        else {
            const translates = data['Translates'];
            const searchOptions = data['SearchOptions'];
            const mainClass = isListRow ? "drive-your-dream-car" : "drive-your-dream-car tile";
            const buttonClass = isListRow ? "mosaic-button" : "list-button";
            
            return (
                <div className={mainClass}>
                    <PageHeader changeListViewHandler={this.changeListView.bind(this)} title={translates.DriveYourDreamCar} buttonClass={buttonClass} />
                    
                    <SearchFilter
                        translates={translates}
                        searchOptions={searchOptions}
                        handleSelectFilter={this.handleSelectFilter.bind(this)}
                    />
                    
                    <div className="list-container">
                        {filteredPosts.map(({Id, Title, DateFormatted, Image, PriceFormatted, BuyTicketLink, ColorType}) =>
                            <Result
                                key={Id}
                                Id={Id}
                                title={Title}
                                href={BuyTicketLink}
                                image={Image}
                                price={PriceFormatted}
                                colorType={ColorType}
                                subtitle={translates.DrivingExperiences}
                                moreInfo={translates.MoreInfo}
                                buyTicket={translates.Buy}
                                offersFrom={translates.OffersFrom}
                            />
                        )}
                    </div>
                    <FooterMenu/>
                </div>
            );
        }
    }
}

const SearchFilter = (props) => (
    <div className="search-container">
        <img src="/content/images/graphics/RacingSchool.svg" alt="racing school" />
        <span>{props.translates.SelectFrom}</span>

        <select onChange={props.handleSelectFilter}>
            <option value="">{props.translates.SelectTheVehicle}</option>
            {props.searchOptions.map(({Id, Title}) =>
                <FilterOption
                    key={Id}
                    Id={Id}
                    title={Title}
                />
            )}
        </select>

    </div>
);

const FilterOption = (props) => (
    <option key={props.Id} value={props.Id}>{props.title}</option>
);

const Result = (props) => (
    <div className={"item-content " + props.colorType}>
        <figure>
            <img src={props.image} alt={props.title} />
        </figure>
        <div>
            <h3>
                <span>{props.subtitle}</span>
                <hr className='points-separator'/>
            </h3>
            <h4>{props.title}</h4>
            <Price offersFrom={props.offersFrom} price={props.price} />
            <div className="buttons">
                <Link to={"/drive-your-dream-car/"+props.Id}>{props.moreInfo}</Link>
                <a href={props.href} target="_blank" rel="noopener noreferrer">{props.buyTicket}</a>
            </div>
        </div>
    </div>
);

class Price extends Component {
    render(){
        if(this.props.price !== ""){
            return(
                <span className="from">{this.props.offersFrom}{ReactHtmlParser(this.props.price)}</span>
            );
        }
        else{
            return(null);
        }
    }
}


export default DriveYourDreamCar;