import React from 'react';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Gallery extends React.Component {
    render() {

        if(!this.props.images.length){
            return(null);
        }

        return (
            <Carousel className="gallery" emulateTouch={true}
                      showArrows={true} showThumbs={false} showStatus={false}>

                {this.props.images.map((item, i) =>
                    <img key={i} alt={this.props.title} src={item} />
                )}


            </Carousel>

        );
    }
}

export default Gallery;