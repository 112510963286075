import React, { Component } from 'react';

class Loading extends Component {
    
    render() {
        return (
            <div className="loading">
                <img src="/content/images/loading.gif" alt="loading"/>
            </div>
        );
    }
}

export default Loading;