import React, {PureComponent} from 'react';
import {browserHistory} from 'react-router';
import helpers from "./helpers";
import global from "./global";
import Loading from "./loading";
import Error from "./error";
import FooterMenu from './footerMenu';

/* // todo - Do not remove the following comment */
/*global google*/

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    GroundOverlay,
    Marker,
} from "react-google-maps";

class Map extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isToLoad: true,
            error: null,
            data: [],
            isOpenedCategories: false,
            hasChangedCategory: false,
            isAllCategoriesChecked: helpers.getMapCat(),
            filteredMarkers: [],
            selectedCategories: helpers.getMapFilters(),
            center: {lat: 37.2313715, lng: -8.6301622},
            isPopupVisible: false,
            currentLatLng: {lat: null, lng: null}
        };

        this.handleDetail = this.handleDetail.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.handleAllCategories = this.handleAllCategories.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
    }

    getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    //console.log(position.coords);
                    this.setState(prevState => ({
                        currentLatLng: {
                            ...prevState.currentLatLng,
                            lat: position.coords.latitude, // Math.floor(Math.random() *  (37.229061 - 36.229061 + 1) ) + 36.229061,
                            lng: position.coords.longitude
                        }
                    }))

                    // 37.229061, -8.627859

                }
            )
        }
    };

    handleDetail(id, termId) {
        if(termId === true ) return; // If marker belongs to WC's - doesn't redirect to detail page
        browserHistory.push('/map/' + id);
    }

    handleShowCategories() {
        this.setState({isOpenedCategories: !this.state.isOpenedCategories});
    }

    handleSelectFilter(id) {
        let newSelectedCategories = this.state.selectedCategories;
        let removeFromArray = function (array, element) {
            return array.filter(el => el !== element);
        };

        if (newSelectedCategories.includes(id)) {
            newSelectedCategories = removeFromArray(newSelectedCategories, id);
        } else {
            newSelectedCategories.push(id);
        }

        this.setState({
            selectedCategories: newSelectedCategories,
            hasChangedCategory: !this.state.hasChangedCategory
        });

        if (newSelectedCategories.length < this.state.data['SearchOptions'].length) {
            this.setState({isAllCategoriesChecked: false});
            helpers.setMapCat(false);
        } else if (newSelectedCategories.length === this.state.data['SearchOptions'].length) {
            this.setState({isAllCategoriesChecked: true});
            helpers.setMapCat(true);
        }

        let _this = this;
        setTimeout(function () {
            _this.filterMarkers();
        }, 100);

        helpers.setMapFilters(newSelectedCategories);

    }

    handleClosePopup() {
        this.setState({isPopupVisible: false});
    }

    filterMarkers() {
        const originalPosts = this.state.data['Posts'];
        let filteredPosts = [];

        for (let post = 0; post < originalPosts.length; post++) {
            if (!this.state.selectedCategories.includes(originalPosts[post].TermId)) {
                continue;
            }

            filteredPosts.push(originalPosts[post]);
        }

        this.setState({
            filteredMarkers: filteredPosts
        });

    }

    setAllCategories(allCategories) {
        let _this = this;
        setTimeout(function () {
            let selectedCategories = allCategories ? [] : helpers.getMapFilters();
            const originalCategories = _this.state.data['SearchOptions'];

            if(allCategories){
                for (let post = 0; post < originalCategories.length; post++) {
                    selectedCategories.push(originalCategories[post].Id);
                }
            }
            _this.setState({
                selectedCategories: selectedCategories,
                isAllCategoriesChecked: allCategories
            });
            helpers.setMapCat(allCategories);
            helpers.setMapFilters(selectedCategories);
            
            if(!allCategories) _this.filterMarkers();
            
        }, 100);
    }

    handleAllCategories() {
        if (this.state.isAllCategoriesChecked) {
            this.setState({
                selectedCategories: [],
                filteredMarkers: []
            });
        } else {
            this.setAllCategories(true);
            this.setState({
                selectedCategories: [],
                filteredMarkers: this.state.data['Posts']
            });
        }

        helpers.setMapFilters([]);

        this.setState({isAllCategoriesChecked: !this.state.isAllCategoriesChecked});
        helpers.setMapCat(!this.state.isAllCategoriesChecked);
    }

    openPopup() {
        const _this = this;

        setTimeout(function () {
            _this.setState({isPopupVisible: true});
        }, 8000);
    }

    geolocation = 0;

    componentWillUnmount() {
        clearInterval(this.geolocation);
    }

    componentDidMount() {
        const _this = this;

        _this.getGeoLocation();
        
        this.geolocation = setInterval(function () {
            _this.getGeoLocation();
        }, 5000);

        // Open popup
        this.openPopup();

        // Get from API
        if (helpers.getMapList() == null) {
            // Get list from API
            const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
            const API = global.websiteUrl + language + global.mapList;

            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isToLoad: false,
                            data: result,
                            filteredMarkers: result["Posts"]
                        });

                        this.setAllCategories(true);

                        sessionStorage.setItem(global.sessionStorage.mapList, JSON.stringify(result));
                    },
                    (error) => {
                        this.setState({
                            isToLoad: false,
                            error
                        });
                    }
                )
        } else {
            this.setState({
                isToLoad: false,
                data: helpers.getMapList(),
                filteredMarkers: helpers.getMapList()["Posts"]
            });
            
            this.setAllCategories(helpers.getMapCat());
        }

    }

    render() {
        const {error, isToLoad, center, markers, filteredMarkers, isOpenedCategories, hasChangedCategory, isAllCategoriesChecked, selectedCategories, isPopupVisible, data, currentLatLng} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        } else if (isToLoad) {
            return <Loading/>;
        } else {
            const translates = data['Translates'];
            const searchOptions = data['SearchOptions'];

            return (
                <div className="google-maps-container to-fade-in">

                    <Popup data={data["Popup"]} handleClosePopup={this.handleClosePopup}
                           isPopupVisible={isPopupVisible}/>

                    <CategoriesFilter
                        translates={translates}
                        searchOptions={searchOptions}
                        isOpenedCategories={isOpenedCategories}
                        hasChangedCategory={hasChangedCategory}
                        selectedCategories={selectedCategories}
                        isAllCategoriesChecked={isAllCategoriesChecked}
                        handleSelectFilter={this.handleSelectFilter}
                        handleShowCategories={this.handleShowCategories.bind(this)}
                        handleAllCategories={this.handleAllCategories}
                    />

                    <MapWithGroundOverlay
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDXqcuBDrv3XdSFQilf3liW9SH1lmA6NL8&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{height: `100%`}}/>}
                        containerElement={<div style={{height: `100vh`}}/>}
                        mapElement={<div style={{height: `100vh`}}/>}
                        /*CUSTOM*/
                        center={center}
                        markers={markers}
                        handleDetail={this.handleDetail}
                        posts={filteredMarkers}
                        currentLatLng={currentLatLng}
                    />
                    <FooterMenu/>
                </div>
            );
        }
    }
}

function Popup(props) {
    if (props.data.Active === false || props.isPopupVisible === false) {
        return (null);
    }

    return (
        <div className="popup-container to-fade-in">
            <span className="close" onClick={props.handleClosePopup}><img src="/content/images/graphics/close_popup.svg"
                                                                          alt="close"/></span>
            <div>
                <h2>{props.data.Title}</h2>
                <a target="_blank" rel="noopener noreferrer" href={props.data.LinkUrl}>{props.data.LinkTitle}</a>
            </div>
        </div>
    );
}

const CategoriesFilter = (props) => (
    <div className="search-container">
        <div className={"select-all-area" + (props.isOpenedCategories ? " active" : "")}>
            <span>{props.translates["SelectCategoy"]}</span>
            <div className="select-all">
                <label htmlFor="selectAll" className="checkbox">
                    <input onChange={props.handleAllCategories} checked={props.isAllCategoriesChecked} name="selectAll"
                           id="selectAll" type="checkbox" value=""/>
                    <span>{props.translates["SelectAll"]}</span>
                </label>
            </div>
        </div>
        <div className={props.isOpenedCategories ? "categories-container active" : "categories-container"}>
            <ul data-change={props.hasChangedCategory}>
                {props.searchOptions.map(({Id, Title, Icon}) =>
                    <FilterOption
                        key={Id}
                        Id={Id}
                        title={Title}
                        icon={Icon}
                        selectedCategories={props.selectedCategories}
                        handleSelectFilter={props.handleSelectFilter}
                    />
                )}
            </ul>
        </div>
        <button onClick={props.handleShowCategories}
                className={"colapse" + (props.isOpenedCategories ? " oppened" : "")}>{props.isOpenedCategories ? "" : props.translates["ViewAll"]}</button>
    </div>
);

const FilterOption = (props) => (
    <li className={props.selectedCategories.includes(props.Id) ? "active" : ""}
        onClick={props.handleSelectFilter.bind(this, props.Id)} key={props.Id}>
        <img src={props.icon} alt="item"/>
        <span>{props.title}</span>
    </li>
);

const defaultMapOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    panControl: false,
    zoomControl: false,
    rotateControl: false,
    fullscreenControl: false,
    minZoom: 15,
    maxZoom: 18,
    restriction: {
        latLngBounds: {north: 37.251774, south: 37.206987, west: -8.649352, east: -8.612891},
        strictBounds: false
    }
};

const MapWithGroundOverlay = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={16}
        defaultOptions={defaultMapOptions}
        defaultCenter={props.center}
        resetBoundsOnResize={true}
    >

        <GroundOverlay
            defaultUrl="/content/images/map-gmaps.svg"
            defaultBounds={new google.maps.LatLngBounds(
                new google.maps.LatLng(37.206987, -8.649352), // South west corner
                new google.maps.LatLng(37.251774, -8.612891) // North east corner
            )}
            defaultOpacity={1}
        />
        {props.posts.map(({Latitude, Longitude, Id, Icon, TermId, DisableDetail, IconWidth, IconHeight}) =>
            <Marker key={Id.toString()}
                    position={{lat: parseFloat(Latitude), lng: parseFloat(Longitude)}}
                    icon={{
                        url: Icon === "" ? window.location.protocol + "//" + window.location.host + "/content/images/non.png" : Icon,
                        scaledSize: IconWidth === "" ? new google.maps.Size(40, 48) : new google.maps.Size( parseInt(IconWidth), parseInt(IconHeight))
                    }}
                    onClick={props.handleDetail.bind(this, Id, DisableDetail)}/>
        )}

        <Marker key="9999999"
                position={{lat: parseFloat(props.currentLatLng.lat), lng: parseFloat(props.currentLatLng.lng)}}
                icon={{
                    url: window.location.protocol + "//" + window.location.host + "/content/images/gpsMarker.png",
                    scaledSize: new google.maps.Size(22, 22)
                }}/>
    </GoogleMap>
));


export default Map;