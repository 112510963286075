import React, { PureComponent, Component } from 'react';
import helpers from "./helpers";
import global from "./global";
import Loading from "./loading";
import Error from "./error";
import PageHeader from './pageHeader';
import Social from './socialMedia';
import Gallery from './detailGallery';
import FooterMoreInfo from './footerMoreInfo';
import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router";

class BuyTicketsDetail extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isToLoad: true,
            error: null,
            data: [],
            nextItemId:null,
            previewsItemId:null,
            language: helpers.GetLanguageFromStorage()
        };
    }

    /* Set the state for next and prev items*/
    getPreviewsAndNextItems(){
        const list = helpers.getExperiencesList();
        if(list === null) return;
        
        const posts = list["Posts"];
        const postType = this.state.data.ColorType;
        let newPosts = [];
        for(let item=0; item < posts.length; item++){
            if(posts[item].ColorType !== postType){
                continue;
            }
            newPosts.push(posts[item]);
        }
        
        let isThis = false;
        let hasNext = false;
        
        for(let item=0; item < newPosts.length; item++){
            if(isThis){
                this.setState({nextItemId:newPosts[item].Id});
                hasNext = true;
                break;
            }
            
            if(newPosts[item].Id.toString() === this.props.params.id.toString()){
                isThis = true;
                if(item !== 0){
                    this.setState({previewsItemId:newPosts[item-1].Id});
                }
                else{
                    this.setState({previewsItemId:null});
                }
            }
        }
        
        if(!hasNext){
            this.setState({nextItemId:null});
        }
        
    }

    loadDetail(){
        // Get list from API
        const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
        const API = global.websiteUrl + language + global.driveYourDreamCarDetail + this.props.params.id;

        fetch(API,
            {
                method: "POST",
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isToLoad: false,
                        data: result,
                        language: helpers.GetLanguageFromStorage()
                    });
                    this.getPreviewsAndNextItems();
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isToLoad: false,
                        error
                    });
                }
            )
    }

    componentWillReceiveProps() {
        this.setState({
            isToLoad: true
        });
        setTimeout(function () {
            this.loadDetail();
        }.bind(this), 1);
    }

    componentDidMount() {
        this.loadDetail();
    }
    
    render() {
        const {error, isToLoad, data, nextItemId, previewsItemId, language} = this.state;

        if (error) {
            return <Error message={error.message}/>;
        }
        else if (isToLoad) {
            return <Loading/>;
        }
        else {
            return (
                <div className={"drive-your-dream-car-container to-fade-in " + data.ColorType}>
                    <PageHeader link="/drive-your-dream-car"  title={data.Title} />
                    
                    <div className="gallery-container">
                        <Gallery title={data.Title} images={data.Images} />
                    </div>
                    <Header previewsItemId={previewsItemId} nextItemId={nextItemId} data={data} />
                    <div className="container-desc">
                        <Description description={data.Description} />
                        <RenderGallery title={data.Title} images={data.ImagesGallery} />
                        <Social data={data} />
                        <a className="large-grey-button" href={data.BuyTicketLink} target="_blank" rel="noopener noreferrer">{data.Translates.BuyNow}</a>
                    </div>
                    
                    <Tickets data={data} />
                    
                    <Graphic />
                    
                    <FooterMoreInfo lang={language} translates={data.Translates}/>
                </div>
            );
        }
    }
}

/* START - PRICES TABLE*/
class Tickets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tablePrices: [],
            tableHeader: [],
            hasTable: false
        };
    }

    componentDidMount() {
        this.doHeaderObject();
        this.doTableObject();
    }

    /* Construction of the main object with all the price properties */
    doTableObject(){
        if(this.props.data.VariantsAttr === undefined ||
            this.props.data.VariantsAttr.pa_driving_experiences_date === undefined || 
            this.props.data.VariantsAttr.pa_driving_experiences_kart === undefined ||
            this.props.data.VariantsAttr.pa_driving_experiences_time === undefined  
        ){
            return;
        }

        const allDays = this.props.data.VariantsAttr.pa_driving_experiences_date[0];
        const types = this.props.data.VariantsAttr.pa_driving_experiences_kart[0];
        const time = this.props.data.VariantsAttr.pa_driving_experiences_time[0];
        const variants = this.props.data.Variants;
                
        // Values of the table: prices array
        let pricesArray = [];

        Object.keys(time).map(function(timeKey, index) {
            let row = [];
            const timeValue = time[timeKey];

            row.push(timeValue);
            
            Object.keys(allDays).map(function(dayKey, index) {
                
                Object.keys(types).map(function(typeKey, index) {
                    let hasPrice = false;                    

                    // VARIANTS
                    for(let variant=0; variant < variants.length; variant++) {
                        // If variant doesn't match, keep the loop
                        if (variants[variant].attribute_pa_driving_experiences_time !== timeKey ||
                            variants[variant].attribute_pa_driving_experiences_date !== dayKey ||
                            variants[variant].attribute_pa_driving_experiences_kart !== typeKey ) {
                            continue;
                        }
                        // when find the variant combination fill the price and break the loop
                        hasPrice = true;
                        row.push(variants[variant].price);
                        break;
                    }
                    
                    /* if no exists the current variant combinations, add "--" to the price index of the array*/
                    if(!hasPrice){
                        row.push("--");
                    }
                    return true;
                });
                return true;
            });

            pricesArray.push(row);
            return true;
        });
        
        this.setState({tablePrices:pricesArray, hasTable:true});
    }
    
    /* Construction of the table header object */
    doHeaderObject(){
        if(this.props.data.VariantsAttr === undefined ||
            this.props.data.VariantsAttr.pa_driving_experiences_date === undefined ||
            this.props.data.VariantsAttr.pa_driving_experiences_kart === undefined ||
            this.props.data.VariantsAttr.pa_driving_experiences_time === undefined
        ){
            return;
        }

        let tableHeader = []; // Array to the header strings
        const allDays = this.props.data.VariantsAttr.pa_driving_experiences_date[0];
        const types = this.props.data.VariantsAttr.pa_driving_experiences_kart[0];

        //Header
        Object.keys(allDays).map(function(objectKey, index) {
            const value = allDays[objectKey];
            let newObj = {
                day: value,
                types: []
            };

            Object.keys(types).map(function(objectKey, index) {
                newObj.types.push(types[objectKey]);
                return true;
            });

            tableHeader.push(newObj);
            return true;
        });

        this.setState({tableHeaders: tableHeader});
        //console.log(tableHeader);
    }

    render(){
        const {tablePrices, tableHeaders, hasTable} = this.state;

        if(hasTable){
            return(
                <div className="container-desc container-prices-table">
                    <p className="exp-desc">{this.props.data.Translates.BookInAdvance}</p>

                    <div className="tickets-container">
                        <h3>{this.props.data.Translates.Price}</h3>

                        <div className="table">
                            <header>
                                <div>
                                    <div className="time">{this.props.data.Translates.Time}</div>
                                </div>
                                {tableHeaders.map(({day, types}) =>
                                    <div key={day}>
                                        <div className="day">{day}</div>
                                        <div className="type-container">
                                            {Object.keys(types).map((keyName) => (
                                                <div className="type" key={keyName.toString()}><span>{types[keyName].toString()}</span></div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </header>
                            <div>
                                {Object.keys(tablePrices).map((keyName) => (
                                    <PricesRow key={keyName.toString()} data={tablePrices[keyName]} />
                                ))}
                            </div>
                        </div>

                        <small>{this.props.data.Translates.PricesTableNote}</small>
                    </div>
                </div>
            );
        }
        else {
            return(null);
        }


    }
}

function PricesRow(props) {
    var getFirstPart = function (txt) {
        return txt.split('(')[0];
    },
        getLastPart = function (txt) {
            var split = txt.split('(');
            
            if(split.length > 1){
                return "<span>("+split[1]+"</span>";
            }
            return "";
        };
        
    return(
        <div>
            <div className="prices-row" data-count={props.data.length}>
                {Object.keys(props.data).map((keyName) => (
                    <div key={keyName.toString()}>
                        {ReactHtmlParser(getFirstPart(props.data[keyName].toString().replace(".00", "")))}
                        {ReactHtmlParser(getLastPart(props.data[keyName].toString()))}
                    </div>
                ))}
            </div>
            <hr className='points-separator'/>
        </div>
    );
}

/* END - PRICES TABLE*/

const Header = (props) => (
    <div className="header">
        <div>
            <h2>
                <span>{props.data.Translates.DrivingExperiences}</span>
                <hr className='points-separator'/>
            </h2>
            <h3>{props.data.Title}</h3>
            <OffersFrom offersFrom={props.data.Translates.OffersFrom} price={props.data.PriceFormatted} />
        </div>
        <div className="control-items">
            <PreviewsItem previewsItemId={props.previewsItemId} />
            <NextItem nextItemId={props.nextItemId} />
        </div>
    </div>
);

function PreviewsItem(props) {
    if (props.previewsItemId !== null) {
        return (
            <Link className="prev" to={"/drive-your-dream-car/"+props.previewsItemId}></Link>
        );
    }
    return null;
}

function NextItem(props) {
    if (props.nextItemId !== null) {
        return (
            <Link className="next" to={"/drive-your-dream-car/"+props.nextItemId}></Link>
        )
    }
    return null;
}

function OffersFrom(props) {
    const toRender = props.price !== "";
    if (toRender) {
        return <div className="offers-from">{props.offersFrom} {ReactHtmlParser(props.price)}</div>;
    }
    return null;
}

const Description = (props) => (
    <div className="description-container">{ReactHtmlParser(props.description.replace(">\r\n", ">\r").replace(">\r\n", ">\r").replace("&nbsp;", "<br /><br />"))}</div>
);

function Graphic(){
    const images = ["graphFirstFoward.svg", "graphRace.svg", "graph79.svg"];
    const image = images[Math.floor(Math.random() * images.length)];
    
    return <img className="firstFoward" src={"/content/images/graphics/" + image} alt="AIA"/>;
}

function RenderGallery(props) {
    const toRender = props.images.length;
    if (toRender) {
        return <GalleryContainer title={props.title} images={props.images}  />;
    }
    return null;
}

const GalleryContainer = (props) => (
    <div className="gallery-detail-container">
        {Object.keys(props.images).map((keyName) => (
            <img key={keyName} src={props.images[keyName]} alt={props.title} />
        ))}
        <hr className='points-separator'/>
    </div>
);

export default BuyTicketsDetail;