import React, {PureComponent} from 'react';
import {browserHistory, Link} from 'react-router';
import helpers from "./helpers";
import { slide as Menu } from 'react-burger-menu';
import global from "./global";

class Navigation extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            translates: null,
            logged: helpers.isLogged(),
            menuOpen: false,
            currentLanguage: helpers.GetLanguageFromStorage()
        };

        this.doLogout = this.doLogout.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    doLoginFetch(){
        var formData = new FormData();
        formData.append('username', helpers.getUsername());
        formData.append('password', helpers.getPassword());

        fetch(global.websiteUrl + global.login,
            {
                method: "POST",
                body: formData,
                mode: "cors",
                cache: "no-store",
                credentials: "same-origin",

            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result != null && !result.hasOwnProperty('error') && !result.hasOwnProperty('code')){
                        helpers.setUserAndPassEncoded(helpers.getUsername(), helpers.getPassword());
                        helpers.setUserProfile(JSON.stringify(result));
                        
                        this.setState({logged:true});
                    }
                }
            )
    }

    doLogout(){
        helpers.logout();

        this.setState({
            logged: false
        });
        this.closeMenu();
    }

    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }
    
    closeMenu(){
        this.setState({menuOpen: false});
    }
    
    setLanguage(lang){
        helpers.setLanguage(lang);
        this.setState({
            currentLanguage: lang
        });
        if(window.location.pathname.split('/').length === 3){
            browserHistory.push('/'+window.location.pathname.split('/')[1]);
            this.closeMenu();
        }
        else{
            window.location = window.location.href;
        }
        
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (helpers.getUserProfile() == null && helpers.getUsername() !== null && helpers.getPassword() !== null){
            this.doLoginFetch();
        }
    }
    
    componentDidMount() {
        // Get Translates from API
        let translatesF = helpers.getTranslates();
        
        if(translatesF == null){
            const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
            const API = global.websiteUrl + language  + global.dashboardTranslates;

            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        translatesF = JSON.stringify(result);
                        localStorage.setItem(global.sessionStorage.translates, translatesF);
                        this.setState({
                            translates: helpers.getTranslates().Menu
                        });
                    },
                    (error) => {
                        // ERROR
                    }
                )
        }
        else{
            this.setState({
                translates: translatesF.Menu
            });    
        }

    }
    
    render() {
        const {translates, logged, menuOpen, currentLanguage} = this.state;
        if(translates === null){
            return(null);
        }
        else{
            var ptClass = currentLanguage === "pt" ? "pt active" : "pt";
            var enClass = currentLanguage === "" ? "en active" : "en";
            
            return (
                <Menu width={ 315 } isOpen={menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
                    <MyProfile isLogged={logged} myProfile={translates.MyProfile} />
                    <Link activeClassName="active" onClick={this.closeMenu} to="/buy-tickets">{translates.Calendar}</Link>
                    <Link activeClassName="active" onClick={this.closeMenu} to="/drive-your-dream-car">{translates.Experience}</Link>
                    <Link activeClassName="active" onClick={this.closeMenu} to="/map">{translates.Map}</Link>
                    <Logout isLogged={logged} logout={translates.Logout} doLogout={this.doLogout}/>
                    <div className="languages">
                        <span>{translates.Languages}</span>
                        <Link onClick={this.setLanguage.bind(this, 'pt')} className={ptClass}></Link>
                        <Link onClick={this.setLanguage.bind(this, '')} className={enClass}></Link>
                    </div>
                </Menu>
            );
        }
    }
}

class Logout extends React.Component {
    render() {
        if(this.props.isLogged){
            return(
                <Link className="bm-item" onClick={ this.props.doLogout } id="logout" to="/">{this.props.logout}</Link>
            );
        }
        return(null);
    }
}

class MyProfile extends React.Component {
    render() {
        if(this.props.isLogged){
            return(
                <Link to="/my-profile">{this.props.myProfile}</Link>
            );
        }
        return(null);
    }
}

export default Navigation;