import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Link} from 'react-router';
import helpers from "./helpers";
import global from "./global";
import Loading from "./loading";
import Error from "./error";
import PageHeader from './pageHeader';
import FooterMenu from './footerMenu';

class BuyTickets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isToLoad: true,
            error: null,
            data: [],
            isListRow: helpers.isTypeList() 
        };
    }

    componentDidMount() {
        // Get from API
        if (helpers.getCalendarList() == null) {
            // Get list from API
            const language = helpers.GetLanguageFromStorage() === "" ? "" : helpers.GetLanguageFromStorage() + "/";
            const API = global.websiteUrl + language + global.calendar;

            fetch(API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin",

                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isToLoad: false,
                            data: result
                        });

                        sessionStorage.setItem(global.sessionStorage.calendarList, JSON.stringify(result));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isToLoad: false,
                            error
                        });
                    }
                )
        }
        else{
            this.setState({
                isToLoad: false,
                data: helpers.getCalendarList()
            });
        }

    }
    
    changeListView(){
        const listType = this.state.isListRow ? "tile" : "list";
        localStorage.setItem(global.sessionStorage.buyTicketsListType, listType);
        this.setState({isListRow: !this.state.isListRow});
    }
    
    render() {
        const {error, isToLoad, data, isListRow} = this.state;
                        
        if (error) {
            return <Error message={error.message}/>;
        }
        else if (isToLoad) {
            return <Loading/>;
        }
        else {
            const translates = data['Translates'];
            const posts = data['Posts'];
            const mainClass = isListRow ? "buy-tickets" : "buy-tickets tile";
            const buttonClass = isListRow ? "mosaic-button" : "list-button";
            
            return (
                <div className={mainClass}>
                    <PageHeader changeListViewHandler={this.changeListView.bind(this)} title={translates.Calendar} buttonClass={buttonClass} />
                    <div className="list-container">
                        {posts.map(({Id, Title, DateFormatted, Image, PriceFormatted, BuyTicketLink}) =>
                            <Result
                                key={Id}
                                Id={Id}
                                title={Title}
                                href={BuyTicketLink}
                                image={Image}
                                date={DateFormatted}
                                price={PriceFormatted}
                                subtitle={translates.RaceCalendar}
                                moreInfo={translates.MoreInfo}
                                buyTicket={translates.BuyTicket}
                                offersFrom={translates.OffersFrom}
                            />
                        )}
                    </div>
                    <FooterMenu/>
                </div>
            );
        }
    }
}

const Result = (props) => (
    <div className="item-content">
        <figure>
            <img src={props.image} alt={props.title} />
        </figure>
        <div>
            <h3>
                <span>{props.subtitle}</span>
                <hr className='points-separator'/>
            </h3>
            <span className="date">{ReactHtmlParser(props.date)}</span>
            <h4>{props.title}</h4>
            <Price offersFrom={props.offersFrom} price={props.price} />
            <div className="buttons">
                <Link to={"/buy-tickets/"+props.Id}>{props.moreInfo}</Link>
                <a href={props.href} target="_blank" rel="noopener noreferrer">{props.buyTicket}</a>
            </div>
        </div>
    </div>
);

class Price extends Component {
    render(){
        if(this.props.price !== ""){
            return(
                <span className="from">{this.props.offersFrom}{ReactHtmlParser(this.props.price)}</span>
            );
        }
        else{
            return(null);
        }
    }
}

export default BuyTickets;