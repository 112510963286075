import React, {Component} from 'react';

class FooterMoreInfo extends Component {
    goTop(){
        window.scroll(0, 0);
    }
    
    render(){
        return(
            <footer className="footer-mi-container" data-lang={this.props.lang}>
                <div className="content">
                    <h2>{this.props.translates.ForMoreInfo}</h2>
                    <hr className='points-separator'/>

                    <ul>
                        <li className="phone">
                            <a href={"tel:"+this.props.translates.Phone}>{this.props.translates.Phone}</a>
                        </li>
                        <li className="email">
                            <a href={"mailto:"+this.props.translates.Email}>{this.props.translates.Email}</a>
                        </li>
                        <li className="address">
                            {this.props.translates.AddressTitle}
                            <span>{this.props.translates.AddressDescription}</span>
                        </li>
                    </ul>
                </div>
                <span onClick={this.goTop.bind(this)} className="go-top"> </span>
            </footer>
        );
    }
    
}

export default FooterMoreInfo;