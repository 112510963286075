import React, { Component } from 'react';
import {Link} from 'react-router';
import global from "./global";
import helpers from "./helpers";
import NotFound from './notFound';
import Error from './error';
import Loading from './loading';
import BackButton from './backButton';

class ViewProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToLoad: false,
            error: null,
            data: [],
            isLogged: false
        };
    }
    
    componentDidMount() {
        this.setState({isToLoad: true});
        
        const userProfile = helpers.getUserProfile();
        
        if(userProfile === null){

            let formData = new FormData();
            formData.append('username', helpers.getUsername());
            formData.append('password', helpers.getPassword());

            fetch(global.websiteUrl + global.login,
                {
                    method: "POST",
                    body: formData,
                    mode: "cors",
                    cache: "no-store",
                    credentials: "same-origin"
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isToLoad: false,
                            isLogged: true,
                            data: result
                        });

                        if(result != null && !result.hasOwnProperty('error') && !result.hasOwnProperty('code')){
                            helpers.setUserProfile(JSON.stringify(result));

                            // Logged In
                            this.setState({isLogged: true});
                        }
                        else {
                            // Not Logged In
                            this.setState({isLogged: false});
                        }

                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            isLogged: false,
                            error
                        });

                        this.setState({isToLoad: false});
                    }
                )
        }
        else{
            this.setState({
                isToLoad: false,
                data: userProfile,
                isLogged: true
            });
        }
        
    }

    render() {
        const { error, data, isLogged, isToLoad} = this.state;

        if (error) {
            return <Error message={error.message} />;
        }
        else if (isToLoad) {
            return <Loading/>;
        }
        else if(isLogged){
            return (
                <div className="view-profile">
                    <header>
                        <BackButton />
                        <figure>
                            <img src={data.picture} alt={data.firstName + " " + data.lastName} />
                            <figcaption>{data.firstName} {data.lastName}</figcaption>
                        </figure>
                        <div>
                            <Link to='edit-profile'>{data.translates != null ? data.translates.editProfile : ""}</Link>
                        </div>
                    </header>
                    <div className="content">
                        <div>
                            <span className="label">{data.translates != null ? data.translates.yourEmail : ""}</span>
                            <span>{data.email !== "" ? data.email : "---"}</span>
                        </div>
                        <hr className='points-separator'/>
                        <div>
                            <span className="label">{data.translates != null ? data.translates.address : ""}</span>
                            <span>
                                {data.address} <br/>
                                {data.zipcode} {data.city}, {data.countryName}
                            </span>
                        </div>
                        <hr className='points-separator'/>
                        <div>
                            <span className="label">{data.translates != null ? data.translates.company : ""}</span>
                            <span>{data.company !== "" ? data.company : "---"}</span>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return <NotFound />;
        }
    }
}

export default ViewProfile;