import React from 'react';
import {Router, browserHistory, Route} from 'react-router';
import './less/index.css';
import NotFound from './components/notFound';
import Dashboard from './components/dashboard';
import BuyTickets from './components/buyTickets';
import DriveYourDreamCar from './components/driveYourDreamCar';
import ViewProfile from './components/viewProfile';
import EditProfile from "./components/editProfile";
import Slider from './components/intro';
import global from "./components/global";
import Subpages from "./components/subpages";
import BuyTicketsDetail from "./components/buyTicketsDetail";
import DriveYourDreamCarDetail from "./components/driveYourDreamCarDetail";
import Map from "./components/map";
import MapDetail from "./components/mapDetail";

class App extends React.Component {     
    render() {
        return (
            <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
                <Route path="/" component={() => (
                    (localStorage.getItem(global.sessionStorage.intro) != null && localStorage.getItem(global.sessionStorage.intro) === "redirect") ? (
                        <Dashboard/>
                    ) : (
                        <Slider/>
                    )
                )}/>
                <Route path="/my-profile" component={ViewProfile}/>
                <Route path="/edit-profile" component={EditProfile}/>
                
                {/*Sub pages with menu*/}
                <Route path="/" component={Subpages}>
                    <Route path="/buy-tickets" component={BuyTickets} />
                    <Route path="/buy-tickets/:id" component={BuyTicketsDetail} />
                    <Route path="/drive-your-dream-car" component={DriveYourDreamCar}/>
                    <Route path="/drive-your-dream-car/:id" component={DriveYourDreamCarDetail}/>
                    <Route path="/map" component={Map} />
                    <Route path="/map/:id" component={MapDetail}/>
                </Route>
                
                {/*404 - Not found*/}
                <Route path="*" component={NotFound} />
            </Router>
        );
    }
}

export default App;